<template>
    <header class="admin-header">
        <div class="admin-logo">
            <MaltImg src="/cms-front/maltLogo.png" alt="Malt Logo" loading="lazy" width="125" height="37" />
            <div class="admin-title">CMS ADMIN</div>
        </div>
        <nav class="admin-nav">
            <ul>
                <li v-for="item in nav" :key="item.url">
                    <NuxtLink :to="item.url" :class="{selected: item.url.startsWith(route.path)}">{{ item.label }}</NuxtLink>
                </li>
            </ul>
        </nav>
    </header>
</template>

<script lang="ts" setup>
    import {ref} from 'vue';
    import {useRoute} from '#imports';
    import {MaltImg} from '#components';

    const route = useRoute();
    const nav = ref([
        {url: '/cms/admin/dashboard', label: 'Dashboard'},
        {url: '/cms/admin/search', label: 'Slice Finder'},
    ]);
</script>

<style lang="scss">
    .admin-header {
        display: flex;
        align-items: center;
        gap: var(--joy-core-spacing-8);
        padding: var(--joy-core-spacing-2) var(--joy-core-spacing-4);
        margin-bottom: var(--joy-core-spacing-6);
    }
    .admin-logo {
        text-align: right;
    }
    .admin-title {
        font-size: var(--joy-font-size-primary-100);
        font-weight: var(--joy-font-weight-bold);
        color: black;
        margin-bottom: 0;
    }
    .admin-nav {
        ul {
            list-style-type: none;
            display: flex;
            gap: var(--joy-core-spacing-2);
        }
        a {
            text-decoration: none;
            font-weight: bold;
            padding: var(--joy-core-spacing-2) var(--joy-core-spacing-4);
            color: var(--joy-color-primary-50);
            border-radius: var(--joy-core-radius-3);

            &:hover {
                background-color: var(--joy-color-primary-10);
            }
            &.selected {
                background-color: var(--joy-color-primary-50);
                color: white;
            }
        }
    }
</style>
